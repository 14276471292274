<template>
  <calculator-detail title="Dana Pendidikan" description="">
    <div slot="calculator" class="mt-4">
      <b-form-group label-cols-sm="3" label="Inflasi biaya pendidikan">
        <b-input-group append="%" type="number">
          <b-form-input v-model="inflationRate"></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group label-cols-sm="3" label="Usia anak saat ini">
        <b-input-group type="number">
          <b-form-input v-model="childCurrentAge"></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label-cols-sm="3"
        label="Ekspektasi imbal hasil yang diinginkan"
      >
        <b-input-group append="%" type="number">
          <b-form-input v-model="desiredReturnExpectation"></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-table
        responsive
        striped
        bordered
        hover
        :fields="fields"
        :items="items"
        foot-clone
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width: ['usia_anak', 'preparation_period'].includes(field.key)
                ? '100px'
                : '180px',
            }"
          />
        </template>

        <template #cell(usia_anak)="row">
          <b-form-input v-model="row.item.usia_anak"></b-form-input>
        </template>

        <template #cell(biaya_spp)="row">
          <b-form-input v-model="row.item.biaya_spp" v-currency></b-form-input>
        </template>

        <template #foot(education)>&nbsp;</template>
        <template #foot(usia_anak)>&nbsp;</template>
        <template #foot(biaya_spp)>&nbsp;</template>
        <template #foot(preparation_period)>Total</template>
        <template #foot(funds_after_inflation)>{{
          totalFundsAfterInflation
        }}</template>
        <template #foot(required_funds)>{{ totalRequiredFunds }}</template>
        <template #foot(required_funds_per_year)>{{
          totalRequiredFundsPerYear
        }}</template>
        <template #foot(required_funds_per_month)>{{
          totalRequiredFundsPerMonth
        }}</template>
      </b-table>

      <b-button @click="calculate" variant="danger">Hitung</b-button>
    </div>
  </calculator-detail>
</template>

<script>
import CalculatorDetail from '../../components/CalculatorDetail.vue'
import * as formulajs from '@formulajs/formulajs'

export default {
  name: 'DanaPendidikan',
  components: {
    CalculatorDetail,
  },
  data() {
    return {
      fields: [
        {
          key: 'education',
          label: 'Jenjang pendidikan',
        },
        {
          key: 'usia_anak',
          label: 'Usia anak saat masuk',
        },
        {
          key: 'biaya_spp',
          label: 'Biaya SPP saat ini',
        },
        {
          key: 'preparation_period',
          label: 'Jangka waktu persiapan (dlm tahun)',
        },
        {
          key: 'funds_after_inflation',
          label: 'Dana setelah inflasi',
        },
        {
          key: 'required_funds',
          label: 'Dana yang diperlukan sekaligus',
        },
        {
          key: 'required_funds_per_year',
          label: 'Dana yang diperlukan setiap tahun',
        },
        {
          key: 'required_funds_per_month',
          label: 'Dana yang diperlukan setiap bulan',
        },
      ],
      items: [],
      inflationRate: '',
      childCurrentAge: '',
      desiredReturnExpectation: '',
      totalFundsAfterInflation: 0,
      totalRequiredFunds: 0,
      totalRequiredFundsPerYear: 0,
      totalRequiredFundsPerMonth: 0,
      mode: 0,
    }
  },
  mounted() {
    const educationLevels = ['SD', 'SMP', 'SMA', 'S1']

    educationLevels.forEach((education) => {
      const item = {
        education: education,
        biaya_spp: '',
        usia_anak: '',
        funds_after_inflation: '',
        preparation_period: '',
        required_funds: '',
        required_funds_per_year: '',
        required_funds_per_month: '',
      }

      this.items.push(item)
    })
  },
  computed: {
    r() {
      return parseFloat(this.inflationRate) / 100
    },
    i() {
      const desiredReturnExpectation =
        parseFloat(this.desiredReturnExpectation) / 100

      return desiredReturnExpectation
    },
  },
  methods: {
    calculate() {
      let totalFundsAfterInflation = 0
      let totalRequiredFunds = 0
      let totalRequiredFundsPerYear = 0
      let totalRequiredFundsPerMonth = 0

      for (let index = 0; index < this.items.length; index++) {
        const fv = this.calculateFundsAfterInflation(index)
        const n = this.calculatePreparationPeriod(index)

        const requiredFunds = this.calcuateRequiredFunds(index, fv, n)
        const requiredFundsPerYear = this.calculateRequiredFundsPerYear(
          index,
          fv,
          n
        )

        const requiredFundsPerMonth = this.calculateRequiredFundsPerMonth(
          index,
          fv,
          n
        )

        totalFundsAfterInflation += parseFloat(fv)
        totalRequiredFunds += parseFloat(requiredFunds)
        totalRequiredFundsPerYear += parseFloat(requiredFundsPerYear)
        totalRequiredFundsPerMonth += parseFloat(requiredFundsPerMonth)
      }

      this.totalFundsAfterInflation = this.formatToCurrency(
        totalFundsAfterInflation
      )
      this.totalRequiredFunds = this.formatToCurrency(totalRequiredFunds)
      this.totalRequiredFundsPerYear = this.formatToCurrency(
        totalRequiredFundsPerYear
      )
      this.totalRequiredFundsPerMonth = this.formatToCurrency(
        totalRequiredFundsPerMonth
      )
    },
    calculateFundsAfterInflation(index) {
      let result = 0

      const spp = this.items[index].biaya_spp
      const usiaAnakSaatMasuk = this.items[index].usia_anak

      if (!this.isEmpty(spp) && !this.isEmpty(usiaAnakSaatMasuk)) {
        const pv = this.toZeroMinus(this.unformatCurrency(spp))
        const n = this.calculatePreparationPeriod(index)

        result = formulajs.FV(this.r, n, 0, -pv, this.mode).toFixed(2)
      }

      this.items[index].funds_after_inflation = this.formatToCurrency(result)

      return result
    },
    calculatePreparationPeriod(index) {
      const childCurrentAge = this.childCurrentAge
      const currentAge = this.items[index].usia_anak
      const result = currentAge - childCurrentAge

      this.items[index].preparation_period = result

      return result
    },
    calcuateRequiredFunds(index, fv, n) {
      const pv = formulajs.PV(this.i, n, 0, fv, this.mode) * -1
      const result = pv.toFixed(2)

      this.items[index].required_funds = this.formatToCurrency(result)

      return result
    },
    calculateRequiredFundsPerYear(index, fv, n) {
      const pmt = formulajs.PMT(this.i, n, 0, fv, this.mode) * -1
      const result = pmt.toFixed(2)

      this.items[index].required_funds_per_year = this.formatToCurrency(result)

      return result
    },
    calculateRequiredFundsPerMonth(index, fv, n) {
      const iPerMonth = this.i / 12
      const nPerMonth = n * 12

      const pmt = formulajs.PMT(iPerMonth, nPerMonth, 0, fv, this.mode) * -1
      const result = pmt.toFixed(2)

      this.items[index].required_funds_per_month = this.formatToCurrency(result)

      return result
    },
  },
}
</script>
